
    .paper-wrapper {
        width: 100%;
        height: 100%;
        /*background: #fff;*/
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .paper-container {
            width: 1200px;
            margin: 0 auto;
            background: #fff;
            padding: 20px;
            box-sizing: border-box;
            .paper-title {
                text-align: center;
                color: #409eff;
                font-size: 24px;
                font-weight: 500;
            }
            .paper-content {
                margin-top: 20px;

            }
        }
    }
